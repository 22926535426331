import { useState, useEffect } from "react";
import Fila3InCompany from "../../img/home/fila3incompany.png";
import Fila3AMedida from "../../img/home/fila3amedida.png";
import Fila3Traducciones from "../../img/home/fila3traducciones.png";
import francia from "../../img/home/francia.png";
import suecia from "../../img/home/suecia.png";
import inglaterra from "../../img/home/ingles.png";
import espania from "../../img/home/esp.png";

function Col3Fila2() {
	const [slides, setSlide] = useState([francia, suecia, inglaterra, espania]);
	const textosBanderas = ["Francés", "Sueco", "Inglés", "Español"];
	const [stoppedInterval, setStopped] = useState(false);
	const [index, setIndex] = useState();

	useEffect(() => {
		let i = 0;
		const myInterval = setInterval(() => {
			if (stoppedInterval) {
				i = i + 1;
				clearInterval(myInterval);
				return;
			}

			if (i < 3) {
				setIndex(i + 1);
				i = i + 1;
				console.log(i);
			} else {
				setIndex(0);
				i = 0;
				return;
			}
		}, 3000);

		return () => clearInterval(myInterval);
	}, [stoppedInterval]);

	return (
		<div class='col3 tips-servicios'>
			<div>
				<a href='servicios.php#incompany'>
					<img src={Fila3InCompany} alt='trabajadora en su puesto de trabajo' />
				</a>
				<div className='servicios-subcard'>
					<h3>In Company</h3>
					<p>
						Te ofrecemos cursos específicos de inglés a la medida de las necesidades
						de tu empresa.
					</p>
				</div>
			</div>
			<div>
				<a href='contacto'>
					<img src={Fila3AMedida} alt='diagrama de una clase a medida' />
				</a>
				<div className='servicios-subcard'>
					<h3>A Medida</h3>
					<p>
						Plantéanos lo que necesitas y elaboraremos un programa personalizado para
						que consigas tu objetivo lo más rápido posible.
					</p>
				</div>
			</div>
			<div>
				{/* <a href='servicios.php#traducciones'>
					<img src={Fila3Traducciones} alt='botón de translate' />
				</a> */}
				{slides &&
					slides.map((slide, i) => {
						if (i === index)
							return (
								<a id='slider-image-showing'>
									<img
										onMouseOver={() => setStopped(true)}
										onMouseOut={() => setStopped(false)}
										src={slide}
									/>
								</a>
							);
					})}
				<div className='servicios-subcard'>
					<h3>Traducciones</h3>
					<p>
						Disponemos de traductores en varios idiomas. Ház click en la imagen para
						mas información.{" "}
					</p>
				</div>
			</div>
		</div>
	);
}

export default Col3Fila2;
