import "../../css/contacto.css";
import { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

function Contacto({ scrollPoint }) {
	const [notRobot, setNotRobot] = useState(false);
	const [warningBorder, setWarningBorder] = useState(false);

	useEffect(() => {
		if (scrollPoint >= 0) {
			window.scroll({
				top: scrollPoint,
				left: 20,
				behavior: "smooth",
			});
		}
	}, []);

	function notARobot(token) {
		if (warningBorder == true) {
			document.getElementById("captcha").style.border = "none";
		}
		if (token) {
			setNotRobot(true);
			document.getElementById("captcha").style.border = "2px solid darkgreen";
		}
	}
	function resetCaptcha() {
		setNotRobot(false);
		document.getElementById("captcha").style.border = "none";
		setWarningBorder(false);
	}

	const updateCaptcha = () => {
		if (notRobot == true) {
			document.getElementById("contacto-mail").href =
				"mailto:cristina@speakingup2you.com?subject=Info%20cursos";
		} else {
			let captchaElements = document.getElementsByClassName("g-recaptcha");
			captchaElements = [...captchaElements];
			captchaElements.map((element) => {
				element.style.border = "2px solid red";
			});
			setWarningBorder(true);
		}
	};

	return (
		<section>
			<div class='down'></div>
			<form id='contacto-form' method='post'>
				<label class='textarea contacto'>
					<a
						onClick={updateCaptcha}
						id='contacto-mail'
						href='mailto:cristina@speakingup2you.com?subject=Info%20cursos'
					>
						Mandar correo
					</a>
				</label>
				<br />
				{warningBorder && (
					<ReCAPTCHA
						className='g-recaptcha'
						hl='true'
						sitekey='6LdG9QoUAAAAAAm5VToXZnF7pohXBo1VaGPqceDl'
					/>
				)}

				<p class='privacy-info'>
					Al mandarnos un correo aceptas nuestra política de privacidad.
				</p>
				<br />
				<br />
				<br />
				{/* <div
					id='captcha'
					datacallback={() => notARobot()}
					dataexpiredcallback={() => resetCaptcha()}
					datatheme='light'
					className='g-recaptcha'
					sitekey='6LdG9QoUAAAAAAm5VToXZnF7pohXBo1VaGPqceDl'
				></div> */}

				<br />
				<br />
				<br />
			</form>
		</section>
	);
}

export default Contacto;
