import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function Servicios({ scrollPoint }) {
	const [data, setData] = useState();
	const [clickedElement, setElement] = useState();
	const getData = () => {
		fetch("db/servicios.json")
			.then((res) => res.json())
			.then((res) => setData(res?.servicios))
			.catch((err) => console.log(err));
	};
	useEffect(() => {
		getData();
		if (scrollPoint >= 0) {
			window.scroll({
				top: scrollPoint,
				left: 20,
				behavior: "smooth",
			});
		}
	}, []);
	return (
		<section>
			<div class='down'></div>
			<h1></h1>
			{data &&
				data.map((service, i) => {
					return (
						<div key={i} class='servicio' id='adultos'>
							<h2>{service.title}</h2>
							<hr />
							<div class='img-container'>
								<img id='test-img' src={service.img} alt='' class='imgresumen' />
								<p className='text-test'>{service.main_text}</p>
							</div>
							<div class='clear'></div>
							<div id='accordion'>
								{service.options &&
									service.options.map((option, i) => {
										return (
											<div key={option.title}>
												<input
													type='radio'
													className={clickedElement === i ? "selected-service" : ""}
													name='accoradultos'
													id='option-1'
												/>
												<label onClick={() => setElement(i)} htmlFor='option-1'>
													{option.title}
												</label>
												<article
													className={
														clickedElement === i ? "article-selected" : "not-selected"
													}
												>
													{" "}
													{option.texts.map((text, i) => (
														<p key={`text-${i}`}>{text}</p>
													))}
												</article>
											</div>
										);
									})}
							</div>
						</div>
					);
				})}

			<div class='servicio' id='especificos'>
				<h2>Intensivos</h2>
				<hr />
				<img src='db/img/servicios/especificos.png' alt='' class='imgresumen' />
				<p className='text-test'>
					Hay muchas razones por las que apuntarse a nuestros "sábados gourmet de
					inmersión": Determinados sábados al trimestre, de 10.00h a 20.00h, te
					ofrecemos inglés sin interrupción. Compartirás y aprenderás con un profesor
					nativo preferentemente, por cada dos estudiantes. Nuestro chef te
					sorprenderá con una comida especial. Te ofrecemos además, un café por la
					mañana y snack por la tarde.
					<br />
					Actividades enfocadas a la conversación preparadas por nuestros
					profesionales. ...y todo en un ambiente acogedor que te hará sentir como en
					casa en nuestra "casa rural".
				</p>

				<div class='clear'></div>
			</div>
			<div class='servicio' id='incompany'>
				<h2>In company</h2>
				<hr />
				<img src='db/img/servicios/incompany.png' alt='' class='imgresumen' />
				<p className='text-test'>
					Te ofrecemos cursos específicos de inglés a la medida de las necesidades de
					tu empresa. Nuestros profesionales con amplia experiencia en este tipo de
					cursos conseguirán con clases motivadoras y dinámicas cumplir con los
					objetivos a conseguir, de forma que empleados y gerentes queden ampliamente
					satisfechos.{" "}
				</p>
				<div class='clear'></div>
			</div>

			<div id='traducciones'>
				<h2>Traducciones</h2>
				<hr />
				<img src='' alt='' />
				<p>
					Contamos con un servicio de traductores e intérpretes en los siguientes
					idiomas:
					<h3>SUECO-ESPAÑOL-INGLÉS</h3>
				</p>
				<p class='center'>
					<button>
						<NavLink to='/contacto'>contacto</NavLink>
					</button>
				</p>
			</div>
		</section>
	);
}

export default Servicios;
