import { NavLink, BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContext } from "./contexts/Auth";

import Main from "./components/Inicio/Main";
import Contacto from "./components/Contacto/Contacto";
import AvisoLegal from "./components/Aviso/AvisoLegal";
import Nosotros from "./components/Su2y/Nosotros";
import MainAdmin from "./components/Inicio/admin/MainAdmin";
import Servicios from "./components/servicios/Servicios";
import Footer from "./components/Footer";
import "./css/main.css";
import "./css/home.css";
import "./css/servicios.css";
import "./css/nosotros.css";

import logo from "./img/logo.png";
import languageCert from "./img/logo-language-cert.png";
import hamburgare from "./img/icon/menu.png";

import { useState, useEffect, useContext } from "react";

function App() {
	const [scrollPoint, setScrollPoint] = useState(0);
	const [device, setDevice] = useState("");
	const login = useContext(AuthContext);
	const [loggedIn, setLoggedIn] = useState(false);
	const [mobileMenu, setMobileMenu] = useState(false);

	useEffect(() => {
		// window.addEventListener("scroll", (e) => {
		// 	const width = window.innerWidth;
		// 	if (width < 500 && window.scrollY > 1700 && window.scrollY < 1890)
		// 		setScrollPoint(1600);
		// 	else if (width > 500 && window.scrollY > 500 && window.scrollY < 590)
		// 		setScrollPoint(500);
		// 	setDevice(
		// 		width < 500 ? "mobile" : width > 500 && width < 850 ? "tablet" : "desktop"
		// 	);
		// });
	});

	return (
		<div className='App'>
			{loggedIn && (
				<div id='publicar-cambios'>
					<button>Publicar</button>
					<button onClick={() => setLoggedIn(false)}>Salir</button>
				</div>
			)}
			<AuthContext.Provider value={login}>
				<BrowserRouter>
					<header>
						<div id='logo'>
							<img src={logo} alt='logo company' />
						</div>
						<div id='claim'>
							<h1>PONLE FIN A TUS DIFICULTADES CON LOS IDIOMAS</h1>
						</div>
						<div id='logo-language-cert'>
							<NavLink to='/servicios'>
								{" "}
								<img
									src={languageCert}
									alt='logotipo de certificado'
									onClick={() => setScrollPoint(1500)}
								/>
							</NavLink>
						</div>
					</header>
					<nav>
						<ul>
							<li
								onMouseOver={() => setMobileMenu(true)}
								id='menu-mobile'
								className={mobileMenu ? "menu-mobile-not-showing" : null}
							>
								<img src={hamburgare} alt='menu hamburguesa' />
								<h1>
									<span id='Up2You-subtest'>Speaking</span>Up2You
								</h1>
							</li>
						</ul>
						<ul
							id='navigation-menu'
							onMouseOver={() => setMobileMenu(true)}
							onMouseOut={() => setMobileMenu(false)}
							className={
								mobileMenu ? "navigation-menu-showing" : "navigation-menu-not-showing"
							}
						>
							<li>
								<NavLink to='/' onClick={() => setScrollPoint(0)}>
									inicio
								</NavLink>
							</li>
							<li>
								<NavLink to='/nosotros' onClick={() => setScrollPoint(0)}>
									SpeakingUp2You
								</NavLink>
							</li>
							<li>
								<NavLink to='/servicios' onClick={() => setScrollPoint(0)}>
									servicios
								</NavLink>
							</li>
							<li>
								<NavLink to='/contacto' onClick={() => setScrollPoint(0)}>
									contacto
								</NavLink>
							</li>
						</ul>
					</nav>
					<Routes>
						<Route
							exact
							path='/'
							element={
								<Main
									scrollPoint={scrollPoint}
									loggedIn={loggedIn}
									setLoggedIn={setLoggedIn}
									device={device}
								/>
							}
						/>
						<Route
							path='/admin'
							element={<MainAdmin loggedIn={loggedIn} setLoggedIn={setLoggedIn} />}
						/>

						<Route
							path='/contacto'
							element={<Contacto scrollPoint={scrollPoint} />}
						/>
						<Route
							path='/nosotros'
							element={<Nosotros scrollPoint={scrollPoint} />}
						/>
						<Route
							path='/servicios'
							element={<Servicios scrollPoint={scrollPoint} />}
						/>
					</Routes>
					<Footer />
				</BrowserRouter>
			</AuthContext.Provider>
		</div>
	);
}

export default App;
