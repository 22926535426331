import Ingles from "../../img/home/ingles.png";
import Frances from "../../img/home/francia.png";
import Sueco from "../../img/home/suecia.png";
import Espanol from "../../img/home/esp.png";

function Col4Fila() {
	return (
		<div class='col4 idiomas'>
			<div>
				<img src={Ingles} alt='bandera inglesa' />
				<h4>Inglés</h4>
			</div>
			<div>
				<img src={Frances} alt='bandera francesa' />
				<h4>Francés</h4>
			</div>
			<div>
				<img src={Sueco} alt='bandera sueca' />
				<h4>Sueco</h4>
			</div>
			<div>
				<img src={Espanol} alt='bandera española' />
				<h4>
					Español<span class='subtext'> para extranjeros</span>
				</h4>
			</div>
		</div>
	);
}

export default Col4Fila;
