import { useContext, useEffect, useState } from "react";
import Slider from ".././Slider";
import { AuthContext } from "../../../contexts/Auth";
import { useNavigate } from "react-router-dom";

function MainAdmin({ loggedIn, setLoggedIn }) {
	const login = useContext(AuthContext);
	const navigate = useNavigate();
	const [notSuccesfull, setNotSuccesfull] = useState(false);

	const checkUser = (e) => {
		e.preventDefault();
		const userLoggedIn = login(e.target[0].value, e.target[1].value);
		setLoggedIn(userLoggedIn);
		if (userLoggedIn === false) setNotSuccesfull(true);
		setTimeout(() => {
			setNotSuccesfull(false);
		}, 5000);
	};

	useEffect(() => {
		loggedIn === true && navigate("/");
	}, [loggedIn]);

	return (
		<section>
			<Slider />
			<div id='admin-form'>
				{notSuccesfull ? (
					<p id='not-succesfull'>
						El usuario o la contraseña no son correctos. Espera...
					</p>
				) : (
					<form onSubmit={(e) => checkUser(e)}>
						<label htmlFor='user'>Usuario</label>
						<input id='user' type='text' placeholder='Escribe aquí' />
						<label htmlFor='pass'>Contraseña</label>
						<input id='pass' type='password' placeholder='Escribe aquí' />
						<button type='submit'>ACCEDER</button>
					</form>
				)}
			</div>
		</section>
	);
}

export default MainAdmin;
