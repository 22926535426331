import { useEffect, useState, useRef } from "react";

function Col1Fila({ loggedIn, setLoggedIn }) {
	const [editting, setEditting] = useState();
	const [fila1Data, setFila1Data] = useState();
	const [editImg, setEditImg] = useState();
	const [uploadedImage, setUploadedImg] = useState();
	const textAreaChars = useRef(170);

	const getDataFila1 = () => {
		fetch("db/fila1.json")
			.then((res) => res.json())
			.then((res) => setFila1Data(res.cards))
			.catch((err) => console.log(err));
	};
	useEffect(() => {
		getDataFila1();
	}, []);

	const updateEdittedImg = (e, card, index) => {
		const filteredCard = fila1Data.filter(
			(originalcard) => originalcard === card
		);
		const restOfCards = fila1Data.filter(
			(originalcard) => originalcard !== card
		);
		filteredCard[0].img = URL.createObjectURL(e.target.files[0]);
		restOfCards.splice(index, 0, filteredCard[0]);
		setFila1Data(restOfCards);
	};

	const updateCardText = (e, card, index) => {
		let edittingCard = fila1Data.filter(
			(originalcard) => originalcard === card
		);
		const restOfCards = fila1Data.filter(
			(originalcard) => originalcard !== card
		);
		edittingCard[0].text = e.target.value;
		restOfCards.splice(index, 0, edittingCard[0]);
		setFila1Data(restOfCards);
	};

	return (
		<div class='fila1home'>
			{fila1Data &&
				fila1Data.map((card, i) => {
					return (
						<div
							className={
								editting === `card-${i + 1}`
									? "fila1card card-editting"
									: "fila1card"
							}
						>
							{loggedIn === true && (
								<span
									onClick={() =>
										editting !== `card-${i + 1}`
											? setEditting(`card-${i + 1}`)
											: setEditting()
									}
								>
									✏️
								</span>
							)}
							<div className='editing-container'>
								{editting === `card-${i + 1}` && !editImg && (
									<span onClick={() => setEditImg(true)}>✏️</span>
								)}
								{editImg && editting === `card-${i + 1}` && (
									<input
										id='edit-img-input'
										type='file'
										accept='.png,.jpg,.jpeg'
										onChange={(e) => {
											updateEdittedImg(e, card, i);
											setEditting();
											setEditImg(false);
										}}
									/>
								)}

								<img
									style={
										editting === `card-${i + 1}`
											? { filter: "grayscale(100%) blur(2px)" }
											: { filter: "none" }
									}
									src={card.img}
									alt={card.img_alt}
								/>
							</div>
							<div className='editing-container'>
								{editting === `card-${i + 1}` ? (
									<textarea
										defaultValue={card.text}
										maxLength={180}
										autoFocus={true}
										onChange={(e) => {
											updateCardText(e, card, i);
										}}
									></textarea>
								) : (
									<p>{card.text}</p>
								)}
								<p></p>
							</div>
						</div>
					);
				})}
		</div>
	);
}

export default Col1Fila;
