import { useRef, useState, useEffect } from "react";

import Slider01 from "../../img/slider/slider01.png";
import Slider02 from "../../img/slider/slider02.png";
import Slider03 from "../../img/slider/slider03.png";

function Slider() {
	const [slides, setSlide] = useState([Slider01, Slider02, Slider03]);
	const [index, setIndex] = useState(0);

	useEffect(() => {
		const myInterval = setInterval(() => {
			if (index < 2) setIndex((prev) => prev + 1);
			else {
				setIndex(0);
			}
			console.log(index);
		}, 3000);

		return () => clearInterval(myInterval);
	});

	return (
		<div class='slider'>
			<div id='slider'>
				{slides &&
					slides.map((slide, i) => {
						if (i === index)
							return <img id='slider-image-showing' src={slides[index]} />;
						else return <img className='slider-image-hidden' src={slides[i]} />;
					})}
			</div>
		</div>
	);
}

export default Slider;
