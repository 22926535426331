import { useState, useEffect } from "react";
import Col1Fila from "./Col1Fila";
import Col2Fila from "./Col2Fila";
import Col3Fila1 from "./Col3Fila1";
import Col3Fila2 from "./Col3Fila2";
import Col4Fila from "./Col4Fila";
import Slider from "./Slider";

import logo from "../../img/logo.png";

function Main({ scrollPoint, device, setLoggedIn, loggedIn }) {
	useEffect(() => {
		if (scrollPoint >= 0) {
			window.scroll({
				top: scrollPoint,
				left: 20,
				behavior: "smooth",
			});
		}
	}, []);
	return (
		<section>
			<Slider />
			<div class='underslider'></div>
			<div class='solomovil'>
				{loggedIn && <span>✏️</span>}
				<img src={logo} />
				{loggedIn && <span>✏️</span>}
				<h1>PONLE FIN A TUS DIFICULTADES CON LOS IDIOMAS</h1>
			</div>
			<Col1Fila setLoggedIn={setLoggedIn} loggedIn={loggedIn} />
			{/* <div class='clear'></div> */}
			<Col2Fila scrollPoint={scrollPoint} device={device} />
			{/* <div class='clear'></div> */}
			<Col3Fila1 />
			<Col3Fila2 />
			<Col4Fila />
		</section>
	);
}

export default Main;
