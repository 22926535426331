import Fila2 from "../../img/home/fila2.png";

import { useEffect, useState } from "react";

function Col2File({ scrollPoint, device, scrolledElement }) {
	return (
		<div class='fila2home'>
			<div
				className={
					(scrollPoint === 500 && device == "desktop") ||
					(scrollPoint === 1600 && device == "mobile")
						? "fila2home-animated-move-right"
						: null
				}
			>
				<p>
					Nuestro centro está dedicado a los idiomas, impartiendo clases
					particulares o en grupos reducidos de 2-4 alumnos máximo.
					<span class='mobile-hide'>
						Destinado tanto a adultos como a niños, personalizamos nuestras
						clases dependiendo de la necesidad del alumno para que obtenga el
						resultado esperado en el menor tiempo posible.
					</span>
					Los métodos que utilizamos varían siempre dependiendo del objetivo a
					conseguir, intentando siempre dar clases dinámicas y prácticas.
					Contamos con un equipo de profesores nativos y bilingües, altamente
					cualificados y con larga experiencia. Por eso podemos ofrecer clases
					in-company, clases de preparación a exámenes Cambridge/Trinity/Toefl,
					cursos intensivos de inmersión, conversación o clases de apoyo
					escolar. ¡Plantéanos tu reto y te ayudaremos a conseguirlo !
				</p>
			</div>
			<div
				className={
					(scrollPoint === 500 && device === "desktop") ||
					(scrollPoint === 1600 && device == "mobile")
						? "fila2home-animated-move-left"
						: null
				}
			>
				<img
					src={Fila2}
					alt='imagen de una de las clases con un profesor y una alumna'
				/>
			</div>
		</div>
	);
}

export default Col2File;
