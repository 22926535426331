function AvisoLegal() {
	return (
		<section>
			<div class='down'></div>

			<p>
				© 2016 Speaking Up2You Todos los derechos reservados. son una Marcas
				registradas de Speaking Up2You que ha creado esta Web, con carácter
				informativo, para un uso personal y gratuito. (Introducir el uso)
				<ol>
					<li>
						Los derechos de propiedad intelectual de la Web www.paraninfo.com,
						su diseño, estructura de navegación, código fuente con códigos de
						lenguaje HTML, JAVA, JavaScript, entre otros, sus bases de datos así
						como cada uno de los distintos elementos en él contenidos son
						titularidad de Speaking Up2You, a quien corresponde únicamente el
						ejercicio exclusivo de los derechos de explotación de los mismos en
						cualquier forma y, en especial, los derechos de reproducción,
						distribución, comunicación pública y transformación.
					</li>

					<li>
						Estas condiciones generales regulan el acceso y utilización del
						sitio Web que Speaking Up2You pone gratuitamente a disposición de
						los usuarios de Internet. El acceso a dicho sitio implica su
						aceptación sin reservas. La utilización de determinados servicios
						ofrecidos en este sitio se regirá además por las condiciones
						particulares previstas en cada caso, las cuales se entenderán
						aceptadas por el mero uso de tales servicios.
					</li>

					<li>
						3. Los usuarios que accedan a la Web están expresamente autorizados
						para la visualización de toda la información, la impresión y
						descarga parcial del contenido sólo y exclusivamente si concurren
						las siguientes condiciones:
						<ol>
							<li>
								Que se realice cuando los elementos reproducidos sean destinados
								únicamente para uso personal y privado del usuario. Se prohíbe,
								por tanto, expresamente su utilización con fines comerciales o
								para su distribución, comunicación pública, transformación o
								descompilación, incluyendo su envío a través de correo
								electrónico. También se prohíbe que dichos contenidos sean
								cedidos o transmitidos a terceros o se instalen en cualquier
								servidor conectado directa o indirectamente a una red local e
								Internet, de forma que un grupo de terceros pueda tener acceso a
								dicha información.
							</li>

							<li>
								Que ninguno de los contenidos relacionados en esta Web sean
								modificados de manera alguna.
							</li>

							<li>
								Que ningún gráfico, icono o imagen disponible en esta Web sea
								utilizado, copiado o distribuido separadamente del texto o resto
								de imágenes que lo acompañan. Cualquier infracción de las
								prohibiciones y limitaciones contenidas en este apartado, así
								como en cualquiera de los siguientes- se reputará infracción de
								los derechos de propiedad intelectual titularidad de Speaking
								Up2You y dará lugar a las responsabilidades legalmente
								establecidas al efecto, pudiendo ser perseguida por esta última
								mediante el ejercicio de las acciones administrativas, civiles o
								penales que, en su caso, correspondan.
							</li>
						</ol>
					</li>
				</ol>
			</p>
		</section>
	);
}

export default AvisoLegal;
