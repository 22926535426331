import facebook from "../img/rrss/facebook.png";
import linkedin from "../img/rrss/linkedin.png";
function Footer() {
	return (
		<footer>
			<div class='foot1'>
				Speaking Up2You C/ Diez, 29 El Escorial (Madrid) 607 55 37 03
			</div>

			<div class='foot2'>
				<iframe
					title='mapa'
					class='mapa'
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3029.239836599569!2d-4.066705584893828!3d40.60253177934406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd41752a20f05a4f%3A0x4c48d0463219613e!2sCalle+Diez%2C+29%2C+28292+El+Escorial%2C+Madrid!5e0!3m2!1ses!2ses!4v1475422403777'
					frameborder='0'
					style={{ border: 0 }}
					allowfullscreen
				></iframe>
			</div>
			<div class='foot3 vmenu'>
				<ul>
					<li>
						<a href='aviso_legal.php'>Aviso Legal</a>
					</li>
					<li>
						<a href='privacidad.php'>Política de Privacidad</a>
					</li>
					<li>
						<a href='cookies.php'>Política de Cookies</a>
					</li>
				</ul>
			</div>
			<div class='foot4'>
				<a href='https://www.facebook.com/speakingup2you'>
					<img src={facebook} alt='facebook' />
				</a>
				<a href='https://es.linkedin.com/in/cristina-catal%25C3%25A1-sverdrup-46a29661'>
					<img src={linkedin} alt='linkedin' />
				</a>
				<a href='http://www.tusclasesparticulares.com/academia/speakingup2you.aspx'>
					<img
						src='http://www.tusclasesparticulares.com/favicon.ico'
						alt='tusclasesparticulares'
					/>
				</a>
			</div>

			<div>
				<p class='copyright'>&copy; Copyright Speaking Up2You - 2016</p>
			</div>
		</footer>
	);
}

export default Footer;
