import Fila3adulto from "../../img/home/fila3adulto.png";
import Fila3Nino from "../../img/home/fila3nino.png";
import Fila3Intensivo from "../../img/home/fila3intensivo.png";

function Col3Fila1() {
	return (
		<div class='col3 tips-servicios'>
			<div>
				<a href='servicios.php#adultos'>
					<img src={Fila3adulto} alt='profesor con un adulto estudiando sueco' />
				</a>
				<div className='servicios-subcard'>
					<h3>Adultos</h3>
					<p>
						Nuestros cursos se adaptan a las necesidades del alumno, en base a su
						disponibilidad, tiempo y urgencia del objetivo a conseguir.
					</p>
				</div>
			</div>
			<div>
				<a href='servicios.php#ninos'>
					<img
						src={Fila3Nino}
						alt='niña haciendo ejercicios en de inglés en el sofá'
					/>
				</a>
				<div className='servicios-subcard'>
					<h3>Niños</h3>
					<p>
						Nuestra amplia experiencia en clases de apoyo y preparación a exámenes de
						Cambridge for Schools, avalan nuestros resultados.
					</p>
				</div>
			</div>
			<div>
				<a href='servicios.php#especificos'>
					<img src={Fila3Intensivo} alt='clase intensiva' />
				</a>
				<div className='servicios-subcard'>
					<h3>Intensivos</h3>
					<p>
						¿Necesitas un empujón fuerte a tu fluidez en conversación? ¿Necesitas
						urgentemente mejorar tu inglés oral? Ház click en la imagen
					</p>
				</div>
			</div>
		</div>
	);
}

export default Col3Fila1;
