import { useEffect } from "react";

import cristina from "../../img/equipo/cristina.png";

function Nosotros({ scrollPoint }) {
	useEffect(() => {
		if (scrollPoint >= 0) {
			window.scroll({
				top: scrollPoint,
				left: 20,
				behavior: "smooth",
			});
		}
	}, []);

	return (
		<div id='nosotros-container'>
			<h1 class='title'>Nuestra historia</h1>
			<p class='resumen'>
				Nuestro centro es de reciente creación, desde julio del presente 2016, pero
				surge como la evolución de la experiencia previa de nuestros colaboradores,
				en equipo bajo la iniciativa empresarial de enseñanza de Cristina Catalá
				Sverdrup. Nuestros valores son honestidad, servicio y motivación.
			</p>

			<div class='tarjeta'>
				<img src={cristina} alt='foto de Cristina C. Sverdrup' width='130' />
				<h3>Cristina C. Sverdrup</h3>
				<h5>Directora y profesora</h5>
				<div class='clear'></div>
				<p>
					Mi origen escandinavo y la suerte de contar con familia desperdigada por el
					mundo, me conectaron desde que nací con la necesidad y el disfrute de
					comunicarme en distintos idiomas. <br />
					<br />
					<span class='mobile-hide'>
						Mi pasión por los viajes y las lenguas me llevaron a elegir de muy joven
						la aviación comercial como profesión. Gracias a ella disfruté
						descubr/iendo países distintos y culturas sorprendentes que enriquecieron
						y aumentaron mi necesidad de aprender más.
					</span>{" "}
					Esto me llevó a pasar temporadas viviendo en distintos países aprendiendo
					los idiomas desde la inmersión in situ. <br />
					<br />
					La vida me llevó a descubr/ir mi segunda pasión: la enseñanza. <br />
					<br />
					El poder enseñar aquello que desde pequeña me ha llenado y a lo que he
					dedicado tantos años de mi vida a aprender, me llena de satisfacción.{" "}
					<br />
					<br />
					Si además se añade el ver cómo otros consiguen superar sus dificultades y
					conseguir sus retos, incluso mejorar de trabajo o descubr/ir los idiomas
					como algo motivador, la satisfacción es plena.
				</p>
			</div>
		</div>
	);
}

export default Nosotros;
